<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="12">
                    <ValidationProvider name="sms_to_will_send" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('sms_to_will_send')">
                            <multi-selectbox
                                :multiple="false"
                                :validate-error="errors[0]"
                                :options="willSendOptions"
                                v-model="formData.sms_to_will_send" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider name="explanation" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('explanation')">
                            <b-form-input v-model="formData.explanation"
                                          :placeholder="$t('explanation_for_sms')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider name="message" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('message')">
                            <b-form-textarea v-model="formData.message"
                                             :placeholder="$t('message')"
                                             :state="errors[0] ? false : null"
                                             rows="6"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <ValidationProvider name="message_en" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('message')+' ('+$t('english')+')'">
                            <b-form-textarea v-model="formData.message_en"
                                             :placeholder="$t('message')+' ('+$t('english')+')'"
                                             :state="errors[0] ? false : null"
                                             rows="6"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12" v-if="withMobileNumbers">
                    <ValidationProvider name="mobile_numbers" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('mobile_number')">
                            <lined-textarea v-model="formData.mobile_numbers"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '10em', resize: 'both' }"
                                            :validateError="errors[0]">
                            </lined-textarea>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                {{selectedStudents.length > 0 ? $t('student_selected',{numberOf: selectedStudents.length}) : ''}}
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="send">{{ $t('send') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>

// Components
import LinedTextarea from "@/components/elements/LinedTextarea"

// Services

// Data

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'


export default {
    props: {
        withMobileNumbers: {
            type: Boolean,
            default: true
        },
        selectedStudents: {
            type: [Array, String],
            default: ''
        }
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        LinedTextarea
    },

    data() {
        return {
            formData: {},
            willSendOptions: [
                {
                    text: 'parametreden',
                    value: 'parametreden'
                },
                {
                    text: 'gelecek',
                    value: 'gelecek'
                }
            ]
        }
    },

    created() {

    },

    methods: {
        async send(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('success_send_message'))
                this.$emit('onSent')
            }
        }
    }
}
</script>

